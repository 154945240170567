
import { createRouter, createWebHashHistory } from "vue-router"


const routes = [ 
    { 
        path: '/',
        component: () => import(/* weppackChunkName: "HomeIdentityPage"*/ '@/modules/identity/pages/HomeIdentityPage')},
    { 
        path: '/about',
        component: () => import(/* weppackChunkName: "AboutPage"*/ '@/modules/identity/pages/AboutPage')
    },
    { 
        path: '/:id',
        component: () => import(/* weppackChunkName: "IdentityList"*/ '@/modules/identity/pages/IdentityList')
    },
    { 
        path: '/:pathMach(.*)*',
        component: () => import(/* weppackChunkName: "NoPageFound"*/ '@/modules/shared/pages/NoPageFound')
    },
    {
        path: '/scan',
        component: () => import(/* weppackChunkName: "ScanPage"*/ '@/modules/identity/pages/ScanPage')
    },
    {
        path: '/ficha',
        component: () => import(/* weppackChunkName: "FichaPage"*/ '@/modules/identity/pages/VcardPage')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // forma corta de poner routes: routers
})

export default router
